"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const scryll = (selector1, selector2, config) => {
    let preventScrollEvent = false;
    const isEmpty = (val) => (val === undefined || val === null);
    const getElement = (val) => {
        const el = document.querySelector(val);
        return el;
    };
    config = Object.assign({}, { init: true, direction: 'vertical' }, config);
    const onScroll = () => {
        if (preventScrollEvent) {
            preventScrollEvent = false;
            return;
        }
        preventScrollEvent = true;
    };
    function scrollFactory(node) {
        return function (event) {
            onScroll();
            if (config.direction === 'vertical') {
                //@ts-ignore
                const scrollPercent = event.target.scrollTop * 100 / (event.target.scrollHeight - event.target.offsetHeight);
                //@ts-ignore
                node.scrollTop = Math.round((node.scrollHeight - node.offsetHeight) * scrollPercent / 100);
            }
            else {
                //@ts-ignore
                const scrollPercent = event.target.scrollleft * 100 / (event.target.scrollWidth - event.target.offsetWidth);
                //@ts-ignore
                node.scrollLeft = Math.round((node.scrollWidth - node.offsetWidth) * scrollPercent / 100);
            }
        };
    }
    let onElement1Scroll;
    let onElement2Scroll;
    const init = () => {
        if (isEmpty(selector1) || isEmpty(selector2)) {
            new Error('You most provide a valid selector');
            return;
        }
        const element1 = getElement(selector1);
        const element2 = getElement(selector2);
        onElement1Scroll = scrollFactory(element2);
        onElement2Scroll = scrollFactory(element1);
        if (isEmpty(element1) || isEmpty(element2)) {
            new Error('Scroll element could not be found');
            return;
        }
        preventScrollEvent = false;
        element1.addEventListener('scroll', onElement1Scroll);
        element2.addEventListener('scroll', onElement2Scroll);
    };
    const kill = () => {
        const element1 = getElement(selector1);
        const element2 = getElement(selector2);
        preventScrollEvent = false;
        if (onElement1Scroll)
            element1.removeEventListener('scroll', onElement1Scroll);
        if (onElement2Scroll)
            element2.removeEventListener('scroll', onElement2Scroll);
    };
    if (config.init === true) {
        init();
    }
    return {
        init,
        kill,
    };
};
exports.default = scryll;
